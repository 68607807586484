<template>
  <Content class="notification-result">
    <ErrorNotificationModal
      :showing-modal="isOpenWarningModal"
      :error-message="errorMessage"
      @redirect="redirect"
      @show-modal="closeWarningModalModal($event)"/>
      <NotificationReadyResultBase :notificationResultTitle = "{
                              title: 'MAKING SURE YOU TYPED IT RIGHT',
                              subTitle: 'could you type it again?',
                            }"
                                   :is-confirm-email="true"
                                   @get-confirm-email="updateEmail"
                                   :compare-email="email"
      />
    <ActionButton
      :text="'NEXT'"
      @click-button="saveEmail"
      :disabled-button="disableButton || disableButtonValidation"
    />
  </Content>
</template>

<script>
import fingerPrintBrowser from '@helpers/differentsServices';
import { mapGetters } from 'vuex';
import ErrorNotificationModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ErrorNotificationModal/ErrorNotificationModal.vue';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import NotificationReadyResultBase from '@innerworks_ds/innerworks-storybook-main/src/stories/NotificationReadyResultBase/NotificationReadyResultBase.vue';

export default {
  name: 'ConfirmNotificationEmail',
  components: {
    ActionButton,
    ErrorNotificationModal,
    NotificationReadyResultBase,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  data: () => ({
    confirmEmail: '',
    email: '',
    isOpenWarningModal: false,
    errorMessage: '',
    disableButton: false,
    disableButtonValidation: true,
  }),
  mounted() {
    this.email = this.$route.query.email;
  },
  methods: {
    updateEmail(data) {
      this.confirmEmail = data.email;
      this.disableButtonValidation = !data.checkValidationEmail;
    },
    closeWarningModalModal(value) {
      this.isOpenWarningModal = value;
    },
    redirect(event) {
      this.$router.push(event);
    },
    prepareDataForRequest(checkboxInform) {
      return {
        name: this.getProfile.name,
        surname: this.getProfile.surname ? this.getProfile.surname : '',
        youEmail: this.confirmEmail,
        questionId: process.env.QUESTIONNAIRE_ID,
        uniqueId: localStorage.getItem('uniqueId'),
        fingerPrint: fingerPrintBrowser.getClientData(),
        checkboxInform,
      };
    },
    async saveEmail() {
      if (!this.disableButtonValidation) {
        this.disableButton = true;
        const data = this.prepareDataForRequest(true);
        try {
          await this.$store.dispatch('auth/preRegisterUser', data);
          await this.$router.push({ name: 'share-info' });
          this.disableButton = false;
        } catch (e) {
          this.isOpenWarningModal = true;
          this.errorMessage = e.response.data.message;
          this.disableButton = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  position: relative;
  font-style: $newDefaultFont;
}
</style>
